import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'typeface-roboto'
import welcomeBg from "images/bg1_large_light.jpg";
import { Card, CardActions, CardContent, CardMedia, createTheme, makeStyles, ThemeOptions, Typography, Link, Button, LinearProgress } from '@material-ui/core';
import { useReduxActions, useReduxSelections } from 'tools/lib/reduxStoreAccess';
import { AuthProvider, useAuth, withAuthenticationRequired } from "react-oidc-context";
import { getConfig, getUserManager } from 'lib/userManager';
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import FirstTenant from './FirstTenant';
import { ITenantSummaryModel } from 'proxy/apiProxy';
import { LoadedApplication } from "features/App/LoadedApplication";
import welcomePicture from "images/key_small.jpg"

const defaultPalette = {
    primary: {
        dark: "#547d9b",
        light: "#b5deff",
        main: "#84accc",
    },
    secondary: {
        dark: "#c88719",
        light: "#ffe97d",
        main: "#ffb74d",
    },
    text: {
        primary: "#000000",
        secondary: "#808080"
    }
};

// https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=9FA8DA&secondary.color=FFE082&primary.text.color=000000&secondary.text.color=000000
export function convertMinHeightIntoMarginTop(input: any): any {
    return replaceNode(input);

    function replaceNode(i: any): any {
        if (!i || typeof i !== "object") {
            return i;
        }
        const output: Record<string, any> = {};
        for (const key in i) {
            if (i.hasOwnProperty(key)) {
                const element = i[key];
                const propKey = key === "minHeight" ? "marginTop" : key;
                output[propKey] = replaceNode(element);
            }
        }
        return output;
    }
}

function useTenantTheme(): ThemeOptions {
    const { parameters } = useReduxSelections("app");
    const themeConfig = parameters?.themeConfig;
    return useMemo(() => {
        const parsed = (function () {
            if (!themeConfig) {
                return {};
            }
            try {
                return JSON.parse(themeConfig);
            } catch {
                return {};
            }
        })();
        const themeOptions = Object.assign({ palette: defaultPalette }, parsed as ThemeOptions | undefined);
        return createTheme(themeOptions);
    }, [themeConfig]);
}

export default function App() {
    const logoutUrl = useMemo(() => {
        const returnTo = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;
        return getConfig().authentication.logoutUrl + '?returnTo=' + encodeURI(returnTo);
    }, []);
    const handleUserRemoved = useCallback(() => {
        window.location.href = logoutUrl;
    }, [logoutUrl]);

    const muiTheme = useTenantTheme();

    return <AuthProvider onRemoveUser={handleUserRemoved} userManager={getUserManager()}>
        <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <OidcConnectedApp />
        </MuiThemeProvider>
    </AuthProvider>
}

const OidcConnectedApp = withAuthenticationRequired(AppSwitchState);
function AppSwitchState() {
    const { currentUser, applicationLoading, noTenant, tenantCreating } = useReduxSelections("app");
    const { firstTenantCreate } = useReduxActions("app");
    const { onSigninCallback } = useReduxActions("app");
    const auth = useAuth();
    useEffect(() => {
        const user = auth.user;
        if (user && !user.expired) {
            onSigninCallback(user);
        }
    }, [auth.user, onSigninCallback]);

    if (applicationLoading) {
        return <LoadingApplication />
    }

    if (noTenant) {
        return <NoTenantApplication saving={tenantCreating} onValidate={firstTenantCreate} />;
    }

    if (!currentUser) {
        return <UnknownUserApplication />
    }

    return <LoadedApplication />;
}

function LoadingApplication() {
    return <StartPanel title='Welcome' loading >Loading...</StartPanel>
}
function UnknownUserApplication() {
    return <StartPanel title='Welcome' >Your identifiers are correct, but your user is not granted in the application.</StartPanel>
}

const usePanelStyles = makeStyles(theme => ({
    card: {
        boxShadow: theme.shadows[24],
        width: 650,
        alignSelf: 'center'
    },
    media: {
        height: 0,
        paddingTop: "40%" // 16:10
    },
    actions: {
        display: "flex",
    },
    simpleCardPage: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        position: 'absolute',
        backgroundImage: `url("${welcomeBg}")`,
        backgroundSize: "cover"
    }
}));

interface INoTenantApplicationProps {
    onValidate: (tenant: ITenantSummaryModel) => void,
    saving: boolean
}

function NoTenantApplication({ onValidate, saving }: INoTenantApplicationProps) {
    const classes = usePanelStyles();
    return <div className={classes.simpleCardPage}>
        <FirstTenant onValidate={onValidate} saving={saving} />
    </div>
}

interface IStartPanelProps {
    title: string;
    loading?: boolean;
}

function StartPanel({ title, children, loading }: PropsWithChildren<IStartPanelProps>) {
    const classes = usePanelStyles();
    const auth = useAuth();
    const handleRequestLogin = useCallback(() => auth.signinRedirect(), [auth]);
    const handleLogout = useCallback(() => void auth.removeUser(), [auth]);
    const login = auth.user?.profile?.preferred_username ?? (auth.user?.profile?.sub)?.replace("mail#", "") ?? "<unknown login>";
    return <div className={classes.simpleCardPage}>
        <Card className={classes.card}>
            <CardMedia className={classes.media} image={welcomePicture} />
            {loading && <LinearProgress variant="query" />}
            <CardContent>
                <Typography component="h5" variant="h5">{title}</Typography>
                <Typography component="p">{children}</Typography>
            </CardContent>
            <CardActions className={classes.actions} disableSpacing={true}>
                <Link onClick={handleLogout} >Logout {login}</Link>
                <p style={{ flexGrow: 1 }} />
                <Button onClick={handleRequestLogin}>Authenticate</Button>
            </CardActions>
        </Card>
    </div>
}
